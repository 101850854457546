<template>
  <component :is="commonComponent" id="disclamimer" title="Disclamimer">
    <section id="disclamimer-content" class="pc_padding">
      <div class="common-content-head">
        Disclaimer
      </div>

      <div class="common-content-row">
        Welcome to {{ domainConfig["IAmURL"] }}. The following disclaimer governs your use of our
        website.
        Please read it
        carefully.
      </div>

      <div class="common-content-head">
        1. General Information
      </div>

      <div class="common-content-row">
        The information provided by {{ domainConfig["IAmURL"] }} is for general informational
        purposes
        only. While we strive to
        ensure the accuracy and timeliness of the information, we make no representations or
        warranties of any kind, express
        or implied, about the completeness, accuracy, reliability, suitability, or availability with
        respect to the website or
        the information, products, services, or related graphics contained on the website for any
        purpose.
      </div>

      <div class="common-content-head">
        2. No Professional Advice
      </div>

      <div class="common-content-row">
        The content on {{ domainConfig["IAmURL"] }} is not intended to be a substitute for
        professional
        advice. Always seek the
        advice of qualified professionals regarding any questions you may have about a particular
        matter.
      </div>

      <div class="common-content-head">
        3. External Links
      </div>

      <div class="common-content-row">
        {{ domainConfig["IAmURL"] }} may contain links to third-party websites. These links are
        provided
        for your convenience
        only. We have no control over the content of these sites and accept no responsibility for
        them
        or for any loss or
        damage that may arise from your use of them. The inclusion of any links does not necessarily
        imply a recommendation or
        endorse the views expressed within them.
      </div>

      <div class="common-content-head">
        4. Errors and Omissions
      </div>

      <div class="common-content-row">
        While we endeavor to keep the information on this site accurate and up to date, we cannot
        guarantee that the site
        will be free from errors or omissions. {{ domainConfig["IAmURL"] }} is not responsible for
        any
        errors or omissions, or for
        the results obtained from the use of this information.
      </div>

      <div class="common-content-head">
        5. Personal Responsibility
      </div>

      <div class="common-content-row">
        You acknowledge that you are using our site voluntarily and that any choices, actions, and
        results now and in the
        future are solely your responsibility. {{ domainConfig["IAmURL"] }} will not be liable to
        you or
        any other party for any
        decision made or action taken in reliance on the information given by the site.
      </div>

      <div class="common-content-head">
        6. Limitation of Liability
      </div>

      <div class="common-content-row">
        In no event shall {{ domainConfig["IAmURL"] }}, nor its directors, employees, partners,
        agents,
        suppliers, or affiliates,
        be liable for any indirect, incidental, special, consequential, or punitive damages,
        including
        without limitation,
        loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your
        use
        or inability to use the
        site; (ii) any unauthorized access to or use of our servers and/or any personal information
        stored therein; (iii) any
        interruption or cessation of transmission to or from the site; (iv) any bugs, viruses,
        trojan
        horses, or the like that
        may be transmitted to or through the site by any third party; (v) any errors or omissions in
        any content or for any
        loss or damage incurred as a result of the use of any content posted, emailed, transmitted,
        or
        otherwise made
        available through the site; and/or (vi) the defamatory, offensive, or illegal conduct of any
        third party. In no event
        shall {{ domainConfig["IAmURL"] }} be liable for any claims, damages, or losses arising out
        of or
        in connection with your
        use of the site.
      </div>

      <div class="common-content-head">
        7. Copyright Disclaimer
      </div>

      <div class="common-content-row">
        All images on this website are sourced from Unsplash and are used in accordance with
        Unsplash's licensing agreement. Unsplash offers free high-quality images that can be used
        for free, including for commercial purposes, without needing additional permission from the
        photographers. <br>
        We respect and support the rights of original content creators and ensure compliance with
        Unsplash's licensing terms when using their images. If you believe any content on this
        website infringes your copyright, please contact us, and we will address the issue promptly.
      </div>

      <div class="common-content-head">
        8. Use ofPublic Domainand Royalty-Free Images
      </div>

      <div class="common-content-row">
        Some images on {{ domainConfig["IAmURL"] }} are sourced from public domain or royalty-free
        image
        providers. These images
        are believed to be free from copyright restrictions. However, we cannot guarantee that all
        such images are free from
        copyright claims. If you believe that any image on our site is not properly attributed or
        infringes upon your rights,
        please contact us at {{ domainConfig['IAMEMAIL'] }}.
      </div>

      <div class="common-content-head">
        9. Changes to This Disclaimer
      </div>

      <div class="common-content-row">
        We reserve the right to modify this disclaimer at any time. By continuing to use the site
        after any changes are
        posted, you acknowledge and agree to the updated disclaimer.
      </div>

      <div class="common-content-head">
        10. Contact Us
      </div>

      <div class="common-content-row">
        If you have any questions about this disclaimer, please contact us at
        {{ domainConfig['IAMEMAIL'] }}.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: `Disclaimer | ${this.domainConfig["IAmURL"]}`,
      meta: [
        {
          name: 'description',
          content: `All content on ${this.domainConfig["IAmURL"]} is for informational purposes only. We do not claim ownership of any images displayed. All images are the property of their respective owners. If you believe any content violates your rights, please contact us for removal.`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "domainConfig"
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>